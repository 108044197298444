export const ProjectTables = {
    Organization: 1,
    Person: 2,
    OrganizationCouncil: 3,
    TransHeader: 4,
    Application: 6,
    PersonFunction: 7,
    EmailMessages: 8,
    Competition: 9,
    CompetitionDetails: 10,
    AthletesParticiaption: 11,
    Sites: 12
};

export const FileTypes = {
    png: 'image/png',
    pdf: 'application/pdf',
    jpg: 'image/jpeg'
};

export const DBFileTypes = {
    Certificate: 1,
    Avatar: 2,
    Id: 4,
    AthleteId: 5,
    HealthId: 8,
    DanCup: 10,
    Contract: 11,
    Recruitment: 12,
    JobLicense: 13,
    PaymentReceipt: 14,
    Council: 15,
    ETU: 16,
    AssistantCoachDocument: 17
};

export const FileTypesConfigDataKeys = {
    FunctionId: 'FunctionId',
    EntityTypeId: 'EntityTypeId',
    ApplicationTypeId: 'ApplicationTypeId',
    Competitions: 'Competitions',
    ApplicationTypeId_FunctionId: 'ApplicationTypeId-FunctionId'
};

export const FilesMetaDataFields = {
    lblDateFrom: 'lblDateFrom',
    lblDateTo: 'lblDateTo',
    lblIssuedAt: 'lblIssuedAt',
    lblIssuedBy: 'lblIssuedBy',
    lblNumber: 'lblNumber',
    lblComments: 'lblComments',
    lkCommon: 'lkCommon'
};

export const EmailFilters = {
    Sent: 600,
    Drafts: 610
};

export const OptionDataKey = {
    Organizations: 'Organizations',
    Functions: 'Functions',
    Roles: 'Roles'
};

export const EntityType = {
    Federation: 100,
    RegionalCommittee: 110,
    Union: 120,
    Club: 130,
    Department: 140,
    Athletes: 200,
    Coach: 210,
    Refereed: 220,
    Administration: 230,
    Stuff: 250
};

export const ApplicationType = {
    AthleteNew: 100,
    AthleteClubRenewal: 101,
    AthleteTransfer: 102,
    AthleteNewHealthCertificate: 103,
    AthleteNewBooklet: 104,
    AthleteNewCard: 105,
    OfficalNew: 200,
    ContractRenewal: 201,
    ClubNew: 300,
    ClubUpdate: 301,
    ClubCertificates: 302,
    Renewal: 500,
    OfficalNewDegree: 501
};

export const QubDataTypes = {
    object: 0,
    string: 1,
    number: 2,
    boolean: 3,
    date: 4,
    array: 5,
    price: 6,
    percentage: 7,
    status: 8,
    url: 9,
    dropdown: 10,
    guid: 11
};
export const QueryStatus = {
    idle: 'idle',
    loading: 'loading',
    success: 'success',
    error: 'error'
};

export const TransactionStatus = {
    submitted: 420,
    paid: 430,
    canceled: 490,
    rechecked: 500,
    pendingPayment: 425,
    pending: 410
};

export const PaymentMethod = {
    card: {
        id: 1,
        name: 'PaymentByCard'
    },
    bank: {
        id: 2,
        name: 'PaymentByBank'
    }
};
